<template>
  <div class="news">
    <div class="content">
      <ModTit
        :title="module.langNamesObj[langNamesEnum[languageActive]]"
        :moreText="$t('XB_More')"
        more="/news"
        v-if="module.configuration.titleSetting.isShow"
      />
      <div
        class="card"
        :class="{
          four: module.dataCountRow === 4,
          two: module.dataCountRow === 2,
          ten: module.dataCountRow === 10,
        }"
      >
        <template v-if="dataSource.length">
          <a
            class="item"
            v-for="(item, index) in dataSource"
            :key="index"
            :href="`/news/detail?id=${item.newsId}&ddtab=true`"
            target="_blank"
          >
            <template v-if="module.dataCountRow === 10">
              <div class="title">{{ item.title }}</div>
              <div class="time" v-if="item.publishTime">
                {{ dateFormat(item.publishTime, "YYYY-MM-DD") }}
              </div>
            </template>
            <template v-else>
              <div
                class="cover"
                :style="{ backgroundImage: 'url(' + item.cover + ')' }"
              ></div>
              <div class="info">
                <div class="title">{{ item.title }}</div>
                <div class="bottom">
                  <span v-if="item.publishTime">{{
                    dateFormat(item.publishTime * 1000, "YYYY-MM-DD")
                  }}</span>
                  <span class="view">
                    <EyeOutlined class="icon" />
                    <i>{{ item.viewCount }}</i>
                  </span>
                </div>
              </div>
            </template>
          </a>
        </template>
        <div class="empty-box" v-else>
          <a-empty
            :image="require('@/assets/image/empty.png')"
            :image-style="{
              height: '144px',
            }"
          >
            <template #description>
              <div class="empty-tips">{{ $t("Pub_Lab_NoData") }}</div>
            </template>
          </a-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/utils/tools";
import OpenData from "@/components/OpenData.vue";
import ModTit from "./ModTit.vue";
export default {
  name: "News",
  components: {
    OpenData,
    ModTit,
  },
  props: {
    module: {
      type: Object,
      default: () => {
        return {};
      },
    },
    langNamesEnum: {
      type: Object,
      default: () => {
        return {};
      },
    },
    languageActive: {
      type: String,
      default: "",
    },
    dataSource: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup() {
    return {
      dateFormat,
    };
  },
};
</script>

<style lang="less" scoped>
.news {
  .content {
    max-width: 1200px;
    margin: 0 auto;
    .card {
      .mixinFlex();
      flex-wrap: wrap;
      .item {
        .info {
          .title {
            color: #333;
            font-size: 14px;
            font-weight: 700;
            .mixinEllipsis(40px, 2);
            line-height: 20px;
          }
          .bottom {
            color: #999;
            font-size: 14px;
            line-height: 20px;
            .mixinFlex(space-between; center);
            .view {
              .mixinFlex(flex-start; center);
              .icon {
                font-size: 16px;
                color: #999;
              }
              i {
                font-style: normal;
                margin-left: 4px;
              }
            }
          }
        }
      }
      &.two {
        .item {
          overflow: hidden;
          background-color: #fff;
          margin-bottom: 20px;
          box-shadow: 0 0 10px rgba(148, 148, 148, 0.1);
          position: relative;
          transition: all 0.3s ease;
          &:hover {
            transform: translateY(-4px);
            box-shadow: 0 6px 20px rgba(148, 148, 148, 0.15);
            .info {
              .title {
                color: @color-theme;
              }
            }
          }
          width: calc(50% - 10px);
          .mixinFlex(space-between; center);
          margin-right: 20px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          .cover {
            background-size: 100% 100%;
            background-position: center;
            width: 256px;
            height: 148px;
          }
          .info {
            width: calc(100% - 256px);
            padding: 20px;
            .bottom {
              margin-top: 48px;
            }
          }
        }
      }
      &.four {
        overflow: hidden;
        background-color: #fff;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(148, 148, 148, 0.1);
        position: relative;
        transition: all 0.3s ease;
        &:hover {
          transform: translateY(-4px);
          box-shadow: 0 6px 20px rgba(148, 148, 148, 0.15);
          .info {
            .title {
              color: @color-theme;
            }
          }
        }
        .item {
          width: calc((100% - 20px * (4 - 1)) / 4);
          margin-right: 20px;
          &:nth-child(4n) {
            margin-right: 0;
          }
          .cover {
            background-size: 100% 100%;
            background-position: center;
            width: 100%;
            height: 148px;
          }
          .info {
            padding: 16px;
            .bottom {
              margin-top: 16px;
            }
          }
        }
      }
      &.ten {
        .item {
          width: 100%;
          .mixinFlex(space-between; center);
          margin-bottom: 20px;
          position: relative;
          padding-left: 18px;
          &::before {
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #ccc;
            position: absolute;
            top: 6px;
            left: 0;
          }
          .title {
            color: #333;
            font-size: 14px;
            .mixinEllipsis(20px);
            line-height: 20px;
            width: calc(100% - 110px);
          }
          .time {
            color: #999;
            font-size: 14px;
            line-height: 20px;
          }
          &:hover {
            .title {
              color: @color-theme;
            }
          }
        }
      }
    }
  }
}
.portal-col-70 {
  .news {
    .content {
      .card {
        &.two {
          .item {
            width: calc((100% - 20px * (2 - 1)) / 2);
            margin-right: 20px;
            &:nth-child(2n) {
              margin-right: 0;
            }
            .cover {
              width: 147px;
              height: 85px;
            }
            .info {
              width: calc(100% - 147px);
              padding: 10px;
              .bottom {
                margin-top: 5px;
              }
            }
          }
        }
        &.four {
          .item {
            width: calc((100% - 20px * (3 - 1)) / 3);
            margin-right: 20px;
            &:nth-child(3n) {
              margin-right: 0;
            }
            .cover {
              width: 100%;
              height: 142px;
            }
            .info {
              padding: 14px;
              .bottom {
                margin-top: 14px;
              }
            }
          }
        }
      }
    }
  }
}
.portal-col-50 {
  .news {
    .content {
      .card {
        &.two {
          .item {
            width: 100%;
            margin-right: 0;
          }
        }
        &.four {
          .item {
            width: calc((100% - 20px * (2 - 1)) / 2);
            margin-right: 20px;
            &:nth-child(2n) {
              margin-right: 0;
            }
            .cover {
              width: 100%;
              height: 148px;
            }
            .info {
              padding: 16px;
              .bottom {
                margin-top: 16px;
              }
            }
          }
        }
      }
    }
  }
}
.portal-col-30,
.portal-col-33,
.portal-col-35 {
  .news {
    .content {
      .card {
        &.two {
          .item {
            width: 100%;
            margin-right: 0;
            .cover {
              width: 147px;
              height: 85px;
            }
            .info {
              width: calc(100% - 147px);
              padding: 10px;
              .bottom {
                margin-top: 5px;
              }
            }
          }
        }
        &.four {
          .item {
            width: calc((100% - 10px * (2 - 1)) / 2);
            margin-right: 10px;
            &:nth-child(2n) {
              margin-right: 0;
            }
            .cover {
              width: 100%;
              height: 98px;
            }
            .info {
              padding: 10px;
              .bottom {
                font-size: 12px;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
.empty-box {
  padding: 70px 0;
  width: 100%;
  .empty-tips {
    color: #999;
    font-size: 14px;
    margin-top: 15px;
  }
}
</style>
