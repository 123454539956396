<template>
  <div class="mod-wrap">
    <template v-for="mod in homeConfigData" :key="mod.detailId">
      <section v-if="mod.moduleType === 11">
        <Menu
          :languageLabels="languageLabels"
          :dataSource="mod.configuration.menuSetting"
        />
      </section>
      <section v-else-if="mod.moduleType === 2">
        <Carousel
          :module="mod"
          :dataSource="mod.dataList.BannerList"
          @jump="toBannerURL"
        />
      </section>
      <section v-else-if="mod.moduleType === 3 && mod.dataType === 7">
        <div class="layout-row">
          <Subject
            :module="mod"
            :langNamesEnum="langNamesEnum"
            :languageActive="languageActive"
            :dataSource="mod.dataList.SubjectList"
          />
        </div>
      </section>
      <section v-else-if="mod.moduleType === 3 && mod.dataType === 2">
        <div class="layout-row">
          <Lecturer
            :module="mod"
            :langNamesEnum="langNamesEnum"
            :languageActive="languageActive"
            :dataSource="mod.dataList.LecturerList"
          />
        </div>
      </section>
      <section v-else-if="mod.moduleType === 3 && mod.dataType === 4">
        <div class="layout-row">
          <News
            :module="mod"
            :langNamesEnum="langNamesEnum"
            :languageActive="languageActive"
            :dataSource="mod.dataList.NewsList"
          />
        </div>
      </section>
      <section v-else-if="mod.moduleType === 3 && mod.dataType === 8">
        <div class="layout-row">
          <Knowledge
            :module="mod"
            :langNamesEnum="langNamesEnum"
            :languageActive="languageActive"
            :dataSource="mod.dataList.KnowledgeList"
          />
        </div>
      </section>
      <section v-else-if="mod.moduleType === 3 && mod.dataType === 1">
        <div class="layout-row">
          <Course
            :module="mod"
            :langNamesEnum="langNamesEnum"
            :languageActive="languageActive"
            :dataSource="mod.dataList.CourseList"
          />
        </div>
      </section>
      <section v-else-if="mod.moduleType === 4 && mod.dataType === 5">
        <div class="layout-row">
          <Ad :dataSource="mod.dataList.BannerList" @jump="toBannerURL" />
        </div>
      </section>
      <section v-else-if="mod.moduleType === 5">
        <div class="layout-row">
          <div
            class="layout-row-head"
            v-if="mod.configuration.titleSetting.isShow"
          >
            <div class="left">
              <div class="title">
                {{ mod.langNamesObj[langNamesEnum[languageActive]] }}
              </div>
              <svg
                width="21"
                height="46"
                viewBox="0 0 21 46"
                v-for="svgI in 3"
                :key="svgI"
                class="title-arrow"
              >
                <path d="M16 23L0 46L5 46L21 23L5 0L0 0L16 23Z"></path>
              </svg>
            </div>
            <a
              class="more"
              v-if="mod.configuration.jumpSetting.isShowRight"
              :href="mod.configuration.jumpSetting.url"
              target="_blank"
            >
              {{ mod.langJumpNamesObj[langNamesEnum[languageActive]] }}
              <DoubleRightOutlined
                v-if="mod.configuration.jumpSetting.isShowArrow"
              />
            </a>
          </div>
          <template v-if="mod.moduleCode === 'LAYOUT-100'">
            <a-tabs class="layout-tab-wrap" :animated="false">
              <a-tab-pane
                v-for="tab in mod.children"
                :key="tab.detailId"
                :tab="tab.langNamesObj[langNamesEnum[languageActive]]"
                :forceRender="true"
              >
                <div class="layout-row-content">
                  <div
                    :class="['layout-col', col.class]"
                    :style="{ width: col.configuration.width }"
                    v-for="(col, colIndex) in tab.children"
                    :key="colIndex"
                  >
                    <div class="layout-col-content">
                      <section
                        class="module"
                        v-for="subMod in col.children"
                        :key="subMod.detailId"
                      >
                        <div
                          class="module-content pd0"
                          :class="[
                            { 'sub-module-box': subMod.moduleCode !== 'ad' },
                            { 'sub-module-ad-box': subMod.moduleCode === 'ad' },
                          ]"
                        >
                          <Subject
                            v-if="
                              subMod.moduleType === 3 && subMod.dataType === 7
                            "
                            :module="subMod"
                            :langNamesEnum="langNamesEnum"
                            :languageActive="languageActive"
                            :dataSource="subMod.dataList.SubjectList"
                          />
                          <Lecturer
                            v-else-if="
                              subMod.moduleType === 3 && subMod.dataType === 2
                            "
                            :module="subMod"
                            :langNamesEnum="langNamesEnum"
                            :languageActive="languageActive"
                            :dataSource="subMod.dataList.LecturerList"
                          />
                          <News
                            v-else-if="
                              subMod.moduleType === 3 && subMod.dataType === 4
                            "
                            :module="subMod"
                            :langNamesEnum="langNamesEnum"
                            :languageActive="languageActive"
                            :dataSource="subMod.dataList.NewsList"
                          />
                          <Knowledge
                            v-else-if="
                              subMod.moduleType === 3 && subMod.dataType === 8
                            "
                            :module="subMod"
                            :langNamesEnum="langNamesEnum"
                            :languageActive="languageActive"
                            :dataSource="subMod.dataList.KnowledgeList"
                          />
                          <Course
                            v-else-if="
                              subMod.moduleType === 3 && subMod.dataType === 1
                            "
                            :module="subMod"
                            :langNamesEnum="langNamesEnum"
                            :languageActive="languageActive"
                            :dataSource="subMod.dataList.CourseList"
                          />
                          <Ad
                            v-else-if="
                              subMod.moduleType === 4 && subMod.dataType === 5
                            "
                            :dataSource="subMod.dataList.BannerList"
                            @jump="toBannerURL"
                          />
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </a-tab-pane>
            </a-tabs>
          </template>
          <template v-else>
            <div class="layout-row-content">
              <div
                :class="['layout-col', col.class]"
                :style="{ width: col.configuration.width }"
                v-for="(col, colIndex) in mod.children"
                :key="colIndex"
              >
                <div class="layout-col-content">
                  <section
                    class="module"
                    v-for="subMod in col.children"
                    :key="subMod.detailId"
                  >
                    <div
                      class="module-content pd0"
                      :class="[
                        { 'sub-module-box': subMod.moduleCode !== 'ad' },
                        { 'sub-module-ad-box': subMod.moduleCode === 'ad' },
                      ]"
                    >
                      <Subject
                        v-if="subMod.moduleType === 3 && subMod.dataType === 7"
                        :module="subMod"
                        :langNamesEnum="langNamesEnum"
                        :languageActive="languageActive"
                        :dataSource="subMod.dataList.SubjectList"
                      />
                      <Lecturer
                        v-else-if="
                          subMod.moduleType === 3 && subMod.dataType === 2
                        "
                        :module="subMod"
                        :langNamesEnum="langNamesEnum"
                        :languageActive="languageActive"
                        :dataSource="subMod.dataList.LecturerList"
                      />
                      <News
                        v-else-if="
                          subMod.moduleType === 3 && subMod.dataType === 4
                        "
                        :module="subMod"
                        :langNamesEnum="langNamesEnum"
                        :languageActive="languageActive"
                        :dataSource="subMod.dataList.NewsList"
                      />
                      <Knowledge
                        v-else-if="
                          subMod.moduleType === 3 && subMod.dataType === 8
                        "
                        :module="subMod"
                        :langNamesEnum="langNamesEnum"
                        :languageActive="languageActive"
                        :dataSource="subMod.dataList.KnowledgeList"
                      />
                      <Course
                        v-else-if="
                          subMod.moduleType === 3 && subMod.dataType === 1
                        "
                        :module="subMod"
                        :langNamesEnum="langNamesEnum"
                        :languageActive="languageActive"
                        :dataSource="subMod.dataList.CourseList"
                      />
                      <Ad
                        v-else-if="
                          subMod.moduleType === 4 && subMod.dataType === 5
                        "
                        :dataSource="subMod.dataList.BannerList"
                        @jump="toBannerURL"
                      />
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </template>
        </div>
      </section>
      <section v-else-if="mod.moduleType === 10">
        <Foot :dataSource="mod.configuration.footerSetting" />
      </section>
    </template>
  </div>
  <div class="init-loading" v-if="initLoading">
    <div>
      <LoadingOutlined />
      <p>
        {{ $t("Home.notInit")
        }}<!-- 首页数据未配置，请联系管理员 -->
      </p>
    </div>
  </div>
  <a-back-top>
    <div class="icon">
      <VerticalAlignTopOutlined />
    </div>
  </a-back-top>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import Menu from "./components/Menu.vue";
import Carousel from "./components/Carousel.vue";
import Course from "./components/Course.vue";
import Subject from "./components/Subject.vue";
import News from "./components/News.vue";
import Knowledge from "./components/Knowledge.vue";
import Lecturer from "./components/Lecturer.vue";
import Ad from "./components/Ad.vue";
import Foot from "./components/BottomFoot.vue";
import { portalConfig } from "@/api/user";
import { platformConfig } from "@/api/other";
import { upFavicon } from "@/utils/tools";
import { setLang } from "@/utils/tools";
import { currentHost } from "@/utils/request.js";
export default {
  components: {
    Menu,
    Carousel,
    Course,
    Subject,
    News,
    Knowledge,
    Lecturer,
    Ad,
    Foot,
  },
  setup() {
    const store = useStore();

    const langNamesEnum = {
      "zh-CN": "zh_CN",
      "zh-TW": "zh_TW",
      "en-US": "en_US",
    };
    const languageActive = computed(() => store.getters.lang);

    const languageLabels = ref([]);
    platformConfig({ site: currentHost }).then((res) => {
      if (res.ret == 0) {
        if (res.data.logo2) upFavicon(res.data.logo2);
        if (res.data.theme) {
          document.body.style.setProperty("--theme", res.data.theme);
        }
        if (res.data.mobileName) {
          document.title = res.data.mobileName;
        }
        let languagesArr = res.data.languages.split(",");
        languageLabels.value = [];
        if (languagesArr.length) {
          languagesArr.forEach((item, index) => {
            if (item == "zh_CN") {
              languageLabels.value.push({
                name: "简体中文",
                value: "zh-CN",
                icon: "🇨🇳",
              });
            } else if (item == "zh_TW") {
              languageLabels.value.push({
                name: "繁体中文",
                value: "zh-TW",
                icon: "🇭🇰",
              });
            } else if (item == "en_US") {
              languageLabels.value.push({
                name: "English",
                value: "en-US",
                icon: "🇺🇸",
              });
            }
          });
        } else {
          languageLabels.value.push({
            name: "简体中文",
            value: "zh-CN",
            icon: "🇨🇳",
          });
        }
        setLang(res.data.languages);
      }
    });

    const homeConfigData = ref([]);

    const render = (arr) => {
      arr.forEach((item) => {
        item.configuration = JSON.parse(item.configuration);
        if (item.configuration.titleSetting) {
          item.langNames = item.configuration.titleSetting.langTitles;
          item.langNamesObj = {};
          item.langNames.forEach((item2) => {
            if (item2.key) item.langNamesObj[item2.key] = item2.value;
          });
        }
        if (
          item.configuration.jumpSetting &&
          item.configuration.jumpSetting.langTitles
        ) {
          item.langJumpNamesObj = {};
          item.configuration.jumpSetting.langTitles.forEach((item2) => {
            if (item2.key) item.langJumpNamesObj[item2.key] = item2.value;
          });
        }
        if (item.configuration.width) {
          item.class =
            "portal-col-" +
            item.configuration.width.slice(0, -1).replace(/\..*/, "");
        }
        if (item.moduleCode == "banner") {
          item.moduleType = 2;
        }
        if (item.moduleType == 11) {
          let menus = item.configuration.menuSetting;
          menus.forEach((m) => {
            m.langNamesObj = {};
            m.langNames.forEach((m2) => {
              m.langNamesObj[m2.key] = m2.value;
            });
          });
        }
        if (item.children && item.children.length) render(item.children);
      });
    };

    const initLoading = ref(false);
    const isInitComplete = () => {
      portalConfig({
        domain: currentHost,
      }).then((res) => {
        if (res.ret == 0) {
          let data = res.data.data || [];
          if (!data.length) {
            initLoading.value = true;
          } else {
            initLoading.value = false;
            render(data);
            homeConfigData.value = data;
          }
        }
      });
    };
    isInitComplete();

    const toBannerURL = (item) => {
      if (item.jumpType == 1) {
        if (item.jumpUrl != "") {
          let urlStr =
            item.jumpUrl.indexOf("http") != -1
              ? item.jumpUrl
              : "http://" + item.jumpUrl;
          window.open(
            urlStr.includes("?")
              ? `${urlStr}&ddtab=true`
              : `${urlStr}?ddtab=true`
          );
        }
      } else {
        if (item.resourceId != 0) {
          switch (item.resourceType) {
            case 1: //知识
              window.open("/doc/detail?id=" + item.resourceId + "&ddtab=true");
              break;
            case 2: //课程
              window.open(
                "/course/center?id=" + item.resourceId + "&ddtab=true"
              );
              break;
            case 34: //项目
              window.open(
                "/project/detail?id=" + item.resourceId + "&ddtab=true"
              );
              break;
          }
        }
      }
    };

    return {
      langNamesEnum,
      languageActive,
      languageLabels,
      homeConfigData,
      toBannerURL,
      initLoading,
    };
  },
};
</script>

<style lang="less" scoped>
.init-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 30px;
  color: var(--theme);
}
.mod-wrap {
  min-width: 1200px;
  overflow-x: auto;
  background-color: #f5f5f5;
  & > section {
    padding: 20px 0;
    &:first-child,
    &:last-child,
    &:nth-child(2) {
      padding: 0;
    }
    &:nth-child(3) {
      padding-top: 40px;
    }
  }
  .layout-row {
    background-color: #fff;
    box-shadow: 0 0 15px rgba(148, 148, 148, 0.15);
    padding: 40px 20px 20px;
    width: 1200px;
    margin: 0 auto;

    &-head {
      .mixinFlex(space-between; center);
      border-bottom: 1px solid var(--theme);
      margin-bottom: 20px;
      height: 53px;
      line-height: 46px;
      padding-bottom: 6px;

      .left {
        .mixinFlex(flex-start; center);

        .title {
          background-color: var(--theme);
          padding: 0 12px;
          height: 46px;
          font-size: 24px;
          font-weight: 700;
          color: #fff;
          position: relative;
          margin-right: 16px;

          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: -16px;
            width: 0;
            height: 0;
            border-top: 23px solid transparent;
            border-left: 16px solid var(--theme);
            border-bottom: 23px solid transparent;
          }
        }

        .title-arrow {
          margin-left: -5px;
          fill: var(--theme);
        }
      }

      .more {
        font-size: 14px;
        color: var(--theme);
      }
    }

    ::v-deep(.layout-tab-wrap) {
      &.ant-tabs {
        overflow: unset;
      }

      .ant-tabs-bar {
        margin-bottom: 0;
        border-bottom: none;

        .ant-tabs-nav-wrap {
          margin-bottom: 0;
          padding-bottom: 20px;

          .ant-tabs-tab {
            font-size: 20px;
            font-weight: 600;
            margin: 0 10px;
            padding: 0 6px;
            line-height: 27px;
            position: relative;

            &::before {
              content: "";
              width: 1px;
              height: 17px;
              background-color: #bbb;
              position: absolute;
              top: 5px;
              left: -10px;
            }

            &:first-child {
              margin-left: 0;
              padding-left: 0;

              &::before {
                content: none;
              }
            }
          }
        }

        .ant-tabs-ink-bar-no-animated,
        .ant-tabs-ink-bar-animated {
          display: none !important;
        }
      }
    }

    &-content {
      margin: 0 -10px;
      .mixinFlex(center; space-between);
    }

    .layout-col {
      padding: 0 10px;
      position: relative;
      display: inline-block;

      &-content {
        .module {
          .sub-module-box {
            background-color: #fafafa;
            padding: 20px 20px 0 20px;
            margin-top: 20px;
          }

          .sub-module-ad-box {
            margin-top: 20px;
          }

          &:first-child {
            .sub-module-box,
            .sub-module-ad-box {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
.ant-back-top {
  width: 58px;
  height: 58px;
  line-height: 58px;
  background-color: #fff;
  text-align: center;
  font-size: 16px;
  right: 40px;
  box-shadow: 0 0 15px rgba(148, 148, 148, 0.15);
  .icon {
    font-size: 30px;
    color: #333;
  }
  &:hover {
    .icon {
      color: var(--theme);
    }
  }
}
</style>
