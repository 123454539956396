<template>
  <div class="knowledge">
    <div class="content">
      <ModTit
        :title="module.langNamesObj[langNamesEnum[languageActive]]"
        :moreText="$t('XB_More')"
        more="/knowledge"
        v-if="module.configuration.titleSetting.isShow"
      />
      <div class="card">
        <template v-if="dataSource.length">
          <a
            class="item"
            v-for="(item, index) in dataSource"
            :key="index"
            :href="`/doc/detail?knowledgeId=${item.knowledgeId}&ddtab=true`"
            target="_blank"
          >
            <div
              class="cover"
              :style="{
                backgroundImage: item.coverImg
                  ? 'url(' + item.coverImg + ')'
                  : 'url(' +
                    require('@/assets/image/knowledge_cover_2.png') +
                    ')',
              }"
            ></div>
            <div class="info">
              <div class="title">{{ item.fileName }}</div>
              <div class="notes">
                <div class="notes-item" v-if="useComment === 1">
                  <MessageOutlined class="icon" />
                  {{ item.comments }}
                </div>
                <div class="notes-item" v-if="item.isDown === 1">
                  <DownloadOutlined class="icon" />
                  {{ item.downs }}
                </div>
                <div class="notes-item">
                  <LikeOutlined class="icon" />
                  {{ item.likes }}
                </div>
                <div class="notes-item">
                  <EyeOutlined class="icon" />
                  {{ item.learnCount }}
                </div>
              </div>
              <div class="time">
                {{ dateFormat(item.createTime * 1000, "YYYY-MM-DD") }}
              </div>
            </div>
          </a>
        </template>
        <div class="empty-box" v-else>
          <a-empty
            :image="require('@/assets/image/empty.png')"
            :image-style="{
              height: '144px',
            }"
          >
            <template #description>
              <div class="empty-tips">{{ $t("Pub_Lab_NoData") }}</div>
            </template>
          </a-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { dateFormat } from "@/utils/tools";
import ModTit from "./ModTit.vue";
export default {
  name: "Knowledge",
  components: {
    ModTit,
  },
  props: {
    module: {
      type: Object,
      default: () => {
        return {};
      },
    },
    langNamesEnum: {
      type: Object,
      default: () => {
        return {};
      },
    },
    languageActive: {
      type: String,
      default: "",
    },
    dataSource: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup() {
    const store = useStore();
    return {
      dateFormat,
      useComment: store.getters.companyInfo.useComment,
    };
  },
};
</script>

<style lang="less" scoped>
.knowledge {
  .content {
    max-width: 1200px;
    margin: 0 auto;
    .card {
      .mixinFlex();
      flex-wrap: wrap;
      .item {
        overflow: hidden;
        background-color: #fff;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(148, 148, 148, 0.1);
        position: relative;
        transition: transform 0.3s ease;
        .mixinFlex();
        width: calc(50% - 10px);
        margin-right: 20px;
        &:nth-child(2n) {
          margin-right: 0;
        }
        &:hover {
          transform: translateY(-4px);
          box-shadow: 0 6px 20px rgba(148, 148, 148, 0.15);
          .info {
            .title {
              color: @color-theme;
            }
          }
        }
        .cover {
          background-size: 100% 100%;
          background-position: center;
          width: 111px;
          height: 148px;
        }
        .info {
          width: calc(100% - 111px);
          padding: 16px;
          .title {
            color: #333;
            font-size: 16px;
            font-weight: 500;
            .mixinEllipsis(52px, 2);
          }
          .notes {
            .mixinFlex();
            margin-top: 10px;
            &-item {
              .icon {
                color: #999;
                font-size: 15px;
              }
              margin-right: 14px;
              color: #999;
              font-size: 13px;
              font-weight: 500;
              line-height: 26px;
              &:last-child {
                margin-right: 0;
              }
            }
          }
          .time {
            color: #999;
            font-size: 13px;
            line-height: 26px;
            margin-top: 2px;
          }
        }
      }
    }
  }
}
.portal-col-30,
.portal-col-33,
.portal-col-35,
.portal-col-50 {
  .knowledge {
    .content {
      .card {
        .item {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}
.portal-col-30,
.portal-col-33,
.portal-col-35 {
  .knowledge {
    .content {
      .card {
        .item {
          .info {
            padding: 16px 10px;
          }
          .notes {
            &-item {
              margin-right: 10px;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
.empty-box {
  padding: 70px 0;
  width: 100%;
  .empty-tips {
    color: #999;
    font-size: 14px;
    margin-top: 15px;
  }
}
</style>
